/**
 * pageTitle function
 */

 const pageTitle = () => {
  const __title = document.querySelector('.js-page-title') as HTMLElement;
  let title = __title.innerHTML
  __title.innerHTML = '';

  if (title){
    for( let i = 0; i < title.length; i++ ){
      __title.innerHTML += "<span>" + title[i] + "</span>"
    }
  }
}


export default pageTitle