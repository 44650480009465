import pageTitle from './components/page-title';
import splide from './components/splide';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    pageTitle();
    splide();
  },
  false
)
