/**
 * Initialization and options for Splide js
 */
 declare var Splide: any

 const splide = () => {

  let carousel = document.querySelector('#main-carousel')

  if(carousel) {
    var main = new Splide( '#main-carousel', {
      type      : 'slide',
      rewind    : true,
      rewindSpeed: 1000,
      autoplay : true,
      interval:  4000,
      pauseOnHover: false,
      pagination: false,
      arrows    : true,
    } );
  
    var thumbnails = new Splide( '#thumbnail-carousel', {
      fixedWidth  : 155,
      fixedHeight : 103,
      gap         : 30,
      rewind      : true,
      pagination  : false,
      isNavigation: true,
      arrows    : false,
      breakpoints : {
        1100: {
          gap : 25
        },
        980: {
          gap : 20
        },
        767: {
          gap : 15,
          fixedWidth  : 120,
          fixedHeight : 80,
        }
      },
    } );
  
    main.sync( thumbnails );
    main.mount();
    thumbnails.mount();
  }
  
  const officeDetails = document.querySelectorAll('.js-office-detail')

  if(officeDetails) {
    officeDetails.forEach(element => {
      var officeSlide = new Splide(element.querySelector('.office__gallery'), {
        type      : 'slide',
        rewind    : true,
        rewindSpeed: 1000,
        autoplay : true,
        interval:  4000,
        pauseOnHover: false,
        pagination: false,
        arrows    : true,
      })
      
      var officeThumbnails = new Splide(element.querySelector('.office__gallery-thumbnail'), {
        fixedWidth  : 92,
        fixedHeight : 92,
        gap         : 15,
        rewind      : true,
        pagination  : false,
        isNavigation: true,
        arrows    : false,
        breakpoints : {
          1100: {
            gap : 25
          },
          980: {
            gap : 20
          },
          767: {
            gap : 15,
            fixedWidth  : 90,
            fixedHeight : 60,
          }
        },
      } );
      
      officeSlide.sync(officeThumbnails);
      officeSlide.mount();
      officeThumbnails.mount();
    });
  }

  const officeRelated = document.querySelector('.js-related')

  if(officeRelated){
    var relatedSlides = new Splide(officeRelated, {
      type        : 'loop',
      perPage     : 3,
      interval    :  4000,
      pauseOnHover: false,
      pagination  : false,
      arrows      : true,
      gap         : 55,
      breakpoints : {
        767: {
          perPage : 1
        }
      }
    })

    relatedSlides.mount();
  }

}

export default splide